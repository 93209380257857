import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  private readonly url = 'common-accounting/dashboard';

  constructor(private http: HttpClient) {
  }

  getIncomeSummary(): Observable<any> {
    return this.http.get(`${this.url}/income-summary`);
  }

  getOutgoingSummary(): Observable<any> {
    return this.http.get(`${this.url}/outgoing-payment-summary`);
  }

  getProductInventoryBeginning(): Observable<any> {
    return this.http.get(`${this.url}/beginning-balance/product-inventory`);
  }

  getSupplierLiabilityBeginning(): Observable<any> {
    return this.http.get(`${this.url}/beginning-balance/supplier-liability`);
  }

  getLoanSummaryBeginning(): Observable<any> {
    return this.http.get(`${this.url}/beginning-balance/loan-summary`);
  }

  getProductInventoryEnding(): Observable<any> {
    return this.http.get(`${this.url}/ending-balance/product-inventory`);
  }

  getSupplierLiabilityEnding(): Observable<any> {
    return this.http.get(`${this.url}/ending-balance/supplier-liability`);
  }

  getLoanSummaryEnding(): Observable<any> {
    return this.http.get(`${this.url}/ending-balance/loan-summary`);
  }

  getCustomerLiabilityBeginning(): Observable<any> {
    return this.http.get(`${this.url}/beginning-balance/customer-liability`);
  }

  getCustomerLiabilityEnding(): Observable<any> {
    return this.http.get(`${this.url}/ending-balance/customer-liability`);
  }

  getFixedAssetBeginning(): Observable<any> {
    return this.http.get(`${this.url}/beginning-balance/fixed-asset`);
  }

  getFixedAssetEnding(): Observable<any> {
    return this.http.get(`${this.url}/ending-balance/fixed-asset`);
  }
}
