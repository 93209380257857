import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  private readonly url = 'dchn-sale/dashboard';

  constructor(private http: HttpClient) {
  }

  getDeliveryOrderCount(): Observable<any> {
    return this.http.get(`${this.url}/delivery-order-count`);
  }

  getRevenue(): Observable<any> {
    return this.http.get(`${this.url}/revenue`);
  }

  getDeliveryOrderByDate(): Observable<any> {
    return this.http.get(`${this.url}/delivery-order-by-date`);
  }

  getDeliveryOrderByMonth(): Observable<any> {
    return this.http.get(`${this.url}/delivery-order-by-month`);
  }

  getRevenueByDate(): Observable<any> {
    return this.http.get(`${this.url}/revenue-by-date`);
  }

  getRevenueByMonth(): Observable<any> {
    return this.http.get(`${this.url}/revenue-by-month`);
  }
}
