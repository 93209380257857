import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  constructor(private http: HttpClient) {
  }

  getList(url: string, supplierId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${url}/${supplierId}/return-invoices?lazyLoadEvent=${lazyLoadEvent}`);
  }

  getReturns(url: string, supplierId: string, timePeriodId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${url}/${supplierId}/return-invoices?lazyLoadEvent=${lazyLoadEvent}&timePeriodId=${timePeriodId}`);
  }

  getOthers(url: string, supplierId: string, timePeriodId: string, lazyLoadEvent: string): Observable<any> {
    return this.http.get(`${url}/${supplierId}/other-invoices?timePeriodId=${timePeriodId}`);
  }

  getItems(url: string, supplierId: string, invoiceId: string): Observable<any> {
    return this.http.get(`${url}/${supplierId}/return-invoices/${invoiceId}/items`);
  }

  getOverview(url: string, supplierId: string): Observable<any> {
    return this.http.get(`${url}/${supplierId}/overview`);
  }

}
